import { useEffect } from 'react';
import MainLayout from '../../layouts/MainLayout';
import CenturySubscription from '../Subscription/CenturySubscription';

const CenturyPriceCircle = () => {
  useEffect(() => {
    const storedDarkMode = localStorage.getItem('darkMode') === 'true';
    document.documentElement.classList.toggle('dark', storedDarkMode);
  }, []);

  return (
    <MainLayout>
      <div className="flex flex-col justify-center mx-auto w-full max-w-6xl mt-50 p-20 text-pl_txt_light_4 dark:text-pl_txt_dark_2">
        {/* Hero Section */}
        <div className="flex flex-col items-center text-center mb-40">
          <h1 className="text-36 md:text-48 font-semibold mb-4">
            Join our{' '}
            <span className="font-bold bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text">
              Century Catalysts
            </span>{' '}. . . 
          </h1>
          <p className="text-18 md:text-20 max-w-2xl leading-relaxed">
            Be one of the first 100 catalysts shaping the future of
            legal tech in Sri Lanka! 
          </p>
        </div>

        {/* Benefits Card */}
        <div className="grid md:grid-cols-2 gap-20 mb-40">
          <div className="bg-pl_bg_light_3 dark:bg-pl_bg_dark_3 rounded-10 p-30 border border-pl_border_light_1 dark:border-pl_border_dark_1">
            <h2 className="text-24 font-semibold mb-20 flex items-center">
              <span className="mr-2">🌟</span>
              Premium Benefits
            </h2>
            <ul className="space-y-15">
              <li className="flex items-start">
                <span className="text-pl_prim_color_1 font-bold mr-10">✔</span>
                <div>
                  <p className="font-semibold mb-2">3-year rate lock</p>
                  <p className="text-14 text-pl_txt_light_3 dark:text-pl_txt_dark_3">
                    Lock in your subscription rate for three full years while
                    enjoying all new features
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="text-pl_prim_color_1 font-bold mr-10">✔</span>
                <div>
                  <p className="font-semibold mb-2">Complimentary access</p>
                  <p className="text-14 text-pl_txt_light_3 dark:text-pl_txt_dark_3">
                    Start your journey with 90 days of free access.
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="text-pl_prim_color_1 font-bold mr-10">✔</span>
                <div>
                  <p className="font-semibold mb-2">Early access</p>
                  <p className="text-14 text-pl_txt_light_3 dark:text-pl_txt_dark_3">
                    Be the first to try new features and shape their development
                  </p>
                </div>
              </li>
            </ul>
          </div>

          {/* Limited Availability Card */}
          <div className="bg-pl_bg_light_2 dark:bg-pl_bg_dark_2 rounded-10 p-30 border border-pl_border_light_1 dark:border-pl_border_dark_1">
            <h2 className="text-24 font-semibold mb-20 flex items-center">
              <span className="mr-2">⚡</span>
              Limited Availability
            </h2>
            <ul className="space-y-15">
              <li className="flex items-start">
                <span className="text-pl_prim_color_1 font-bold mr-10">✔</span>
                <div>
                  <p className="font-semibold mb-2">
                    Only 100 positions available
                  </p>
                  <p className="text-14 text-pl_txt_light_3 dark:text-pl_txt_dark_3">
                    Century Catalysts are limited to the first 100 subscribers
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="text-pl_prim_color_1 font-bold mr-10">✔</span>
                <div>
                  <p className="font-semibold mb-2">First come, first served</p>
                  <p className="text-14 text-pl_txt_light_3 dark:text-pl_txt_dark_3">
                    Secure your spot by subscribing before the positions are
                    filled
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="text-pl_prim_color_1 font-bold mr-10">✔</span>
                <div>
                  <p className="font-semibold mb-2">Unmissable value</p>
                  <p className="text-14 text-pl_txt_light_3 dark:text-pl_txt_dark_3">
                    Boost your legal research while shaping its technology
                    future
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/* Subscription Options */}
        <CenturySubscription />

        <div className="text-center">
          <p className="text-12 text-pl_txt_light_3 dark:text-pl_txt_dark_3">
            *Cancel any time - No permanent commitment, cancel your subscription
            at any time{' '}
          </p>
        </div>
      </div>
    </MainLayout>
  );
};

export default CenturyPriceCircle;
