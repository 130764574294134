import React, { useEffect, useState } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { InstantSearch } from 'react-instantsearch';
import { searchClient } from '../services/typesense-client';
import { useSelector } from 'react-redux';
import { useAuthContext } from '@asgardeo/auth-react';

const SearchLayout = ({ children, showLogo, showSearch, isResults }) => {
  const selectedOption = useSelector((state) => state.home.selectedOption);
  const { state, getAccessToken } = useAuthContext();
  const searchType = sessionStorage.getItem('searchType');
  let queryBy;
  let inFix;
  const [userToken, setUserToken] = useState('noToken');

  useEffect(() => {
    const fetchToken = async () => {
      if (state.isAuthenticated) {
        const accessToken = await getAccessToken();
        setUserToken(accessToken);
        sessionStorage.setItem('userToken', userToken);
      } else {
        setUserToken('noToken');
      }
    };
    fetchToken();
  }, [state.isAuthenticated, getAccessToken, userToken]);

  if (searchType === 'Exact Match') {
    queryBy = 'decision_text';
    inFix = 'off';
  } else if (searchType === 'Semantic Match') {
    queryBy = 'summary_vec';
    inFix = 'off';
  } else if (searchType === 'Case Number') {
    queryBy = 'standard_casenumber';
    inFix = 'always';
  } else if (searchType === 'Parties') {
    queryBy = 'nameofparties';
    inFix = 'off';
  } else {
    queryBy = 'decision_text,summary_vec';
    inFix = 'off';
  }

  const SearchClient = searchClient(queryBy, inFix, userToken);
  return (
    <InstantSearch
      indexName={process.env.REACT_APP_TYPESENSE_COLLECTION_NAME}
      searchClient={SearchClient}
      routing={true}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      <div className="container-fluid flex flex-col bg-pl_bg_light_3 dark:bg-pl_bg_dark_1 justify-between min-h-screen">
        <Header showLogo={showLogo} showSearch={showSearch} />

        <main
          className={` ${
            isResults ? 'xl:ml-[220px] xl:w-[100vw-220px]' : 'w-full'
          } mb-40`}
        >
          {children}
        </main>

        <Footer />
      </div>
    </InstantSearch>
  );
};

export default SearchLayout;
