import axios from 'axios';

export const geminiInput = async (query) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN_URL}/autocomplete`,
      {
        query,
      }
    );
    return response.data.completions;
  } catch (error) {
    console.error('Error fetching suggestions:', error);
    return [];
  }
};
