import { useEffect } from 'react';
import MainLayout from "../../layouts/MainLayout";
import { Helmet } from 'react-helmet';

const Privacy = () => {
  useEffect(() => {
    const storedDarkMode = localStorage.getItem('darkMode') === 'true';
    document.documentElement.classList.toggle('dark', storedDarkMode);
  }, []);
  return (
    <MainLayout>
      <Helmet>
          <title>Privacy - Paralegal.lk</title>
          <meta
            name="description"
            content="Learn about Paralegal.lk, a platform providing access to Sri Lankan legal resources, including over 20,000 appellate court decisions since 1880."
          />
          <link rel="canonical" href="https://www.paralegal.lk/privacy" />
        </Helmet>

      <div className="flex flex-col justify-center mx-auto w-full max-w-6xl mt-50 p-20 text-pl_txt_light_4 dark:text-pl_txt_dark_2">
        <h1 className="mb-4 font-bold text-22">Privacy Policy</h1>
        <p className="leading-relaxed mb-2">Effective Date: December 1, 2024</p>
        <p className="leading-relaxed mb-2">
          At {' '}
          <a
            href="https://www.paralegal.lk"
            className="font-bold bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text"
          >
            paralegal.lk
          </a>
          , we are committed to protecting your privacy and the security of your personal information. This Privacy Policy 
          outlines how we collect, use, and safeguard your information when you use our legal search engine platform with 
          an annual subscription. By using 
          {' '}
          <a
            href="https://www.paralegal.lk"
            className="font-bold bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text"
          >
            paralegal.lk
          </a>
          , you consent to the practices described in this policy.
        </p>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Information We Collect</h2>
        <p className="leading-relaxed mb-2">
          When you use our platform, we may collect the following information:
        </p>
        <ul className="list-disc list-inside leading-relaxed mb-2 [text-indent:-1.5rem] [padding-left:3.5rem]">
          <li>
            <strong>Personal Information: </strong>
              <ul className="list-inside leading-relaxed mb-2 [text-indent:0.5rem]">
                <li>-
                  Name, email address, and phone number provided during registration or subscription.
                </li>
                <li>-
                  Company or organization details if applicable.
                </li>
              </ul>
          </li>
          <li>
            <strong>Payment Information: </strong>
              <ul className="list-inside leading-relaxed mb-2 [text-indent:0.5rem]">
                <li>-
                  Billing details processed through <strong>PayHere</strong>, a trusted third-party payment gateway. We do not store your full payment details.
                </li>
              </ul>
          </li>
          <li>
            <strong>Authentication Data: </strong>
              <ul className="list-inside leading-relaxed mb-2 [text-indent:0.5rem]">
                <li>-
                  <strong> WSO2 Asgardeo</strong> is used for identity management, ensuring maximum security and compliance with the OIDC standard.
                </li>
              </ul>
          </li>
          <li>
            <strong>Technical Information: </strong>
              <ul className="list-inside leading-relaxed mb-2 [text-indent:0.5rem]">
                <li>-
                  IP address, browser type, device details, and usage data collected automatically via cookies and similar technologies.
                </li>
              </ul>
          </li>
        </ul>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Use of Information</h2>
        <p className="leading-relaxed mb-2">We use the collected information for the following purposes:</p>
        <ul className="list-disc list-inside leading-relaxed mb-2 [text-indent:-1.5rem] [padding-left:3.5rem]">
          <li>
           <strong>Account Management: </strong>To create, manage, and authenticate user accounts securely.
          </li>
          <li>
            <strong>Service Delivery: </strong>To provide access to our legal search engine and associated features.
          </li>
          <li>
            <strong>Payment Processing: </strong>To facilitate secure subscription billing via <strong>PayHere</strong>.
          </li>
          <li>
            <strong>Customer Support: </strong>To communicate with you regarding your account, respond to inquiries, and provide support.
          </li>
          <li>
            <strong>Platform Improvement: </strong>To enhance our services, based on user feedback and usage patterns.
          </li>
          <li>
            <strong>Security and Compliance: </strong>To detect and prevent fraud, unauthorized access, and ensure compliance with legal obligations.
          </li>
        </ul>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Information Sharing</h2>
        <p className="leading-relaxed mb-2">
          We respect your privacy and will not sell, trade, or transfer your personal information without your consent, except in the following cases:
        </p>
        <ul className="list-disc list-inside leading-relaxed mb-2 [text-indent:-1.5rem] [padding-left:3.5rem]">
          <li>
            <strong>Service Providers: </strong>We share information with trusted third-party providers for payment 
            processing (<strong>PayHere</strong>), identity management (<strong>WSO2 Asgardeo</strong>), and other operational needs. 
            These providers are required to protect your data in accordance with contractual and legal obligations.
          </li>
          <li>
            <strong>Legal Obligations: </strong>We may disclose your information in response to legal requests, court orders, or to comply with applicable laws.
          </li>
        </ul>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Data Security</h2>
        <p className="leading-relaxed mb-2">
          We implement industry-standard security measures, including encryption and secure authentication via 
          <strong> WSO2 Asgardeo</strong> and <strong>PayHere</strong>, to protect your personal information from 
          unauthorized access. However, no online transmission or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </p>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Cookies and Tracking Technologies</h2>
        <p className="leading-relaxed mb-2">
          We use cookies to enhance your user experience, analyze platform usage, and tailor content. You can 
          manage cookie preferences through your browser settings, though disabling cookies may limit certain functionalities.
        </p>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Changes to the Privacy Policy</h2>
        <p className="leading-relaxed mb-2">
          We may update this Privacy Policy from time to time. Any changes will be posted on this page with a 
          revised "Effective Date." Continued use of the platform after such changes constitutes acceptance of the updated policy.
        </p>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Contact Us</h2>
        <p className="leading-relaxed mb-2">
          If you have any questions or concerns about this Privacy Policy or the handling of your personal information, please contact us at:
        </p>
        <p className="leading-relaxed mb-2">
            <strong>Email:</strong> <a href="mailto:admin@paralegal.lk" className="hover:underline text-pl_prim_color_1">admin@paralegal.lk</a>
        </p>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Paralegal (Pvt) Ltd</h2>
        <p className="leading-relaxed mb-2">
            <strong>
                95, Chemmani Road,<br />
                Nallur,<br />
                Jaffna 40000
            </strong>
        </p>
      </div>
      </MainLayout>
  );
};

export default Privacy;
