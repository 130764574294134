import React, { useState } from 'react';
import TermsContent from '../../LegalPages/TermsContent';
import { useNavigate } from 'react-router-dom';
import './TermsPopup.css';

const TermsPopup = ({ onAccept, onClose }) => {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const handleProceed = () => {
    if (isChecked) {
      onAccept();
    } else {
      alert('Please accept the Terms and Conditions before proceeding.');
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-20 md:px-0">
      <div className="bg-pl_bg_light_3 dark:bg-pl_bg_dark_3 text-gray-900 dark:text-gray-100 p-6 rounded-lg w-full max-w-2xl shadow-lg">
        <button
          onClick={handleClose}
          className="mb-10 pr-20 text-gray-500 hover:text-gray-800 dark:hover:text-white text-right w-full"
        >
          &#x2715;
        </button>
        <div className="max-h-96 overflow-y-auto">
          <TermsContent />
        </div>
        <div className="flex items-center justify-between mt-4">
          <label className="pl-checkbox flex items-center space-x-2">
            <input
              type="checkbox"
              className="w-4 h-4 text-pl_prim_color_1 border-gray-300 rounded focus:ring-pl_prim_color_1"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <span>
              I accept the{' '}
              <button
                type="button"
                className="text-pl_prim_color_1 hover:underline"
                onClick={() => navigate('/terms')}
              >
                Terms and Conditions
              </button>
            </span>
          </label>
          <button
            onClick={handleProceed}
            className={`px-4 py-2 text-white rounded ${
              isChecked
                ? 'bg-pl_prim_color_1 hover:bg-pl_prim_color_1'
                : 'bg-gray-400 cursor-not-allowed'
            }`}
            disabled={!isChecked}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsPopup;
