import { useAuthContext } from '@asgardeo/auth-react';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

function UserProfile() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({
    username: '',
    email: '',
  });
  const [userPaid, setUserPaid] = useState(
    sessionStorage.getItem('isPaid') === 'true'
  );
  const menuRef = useRef(null);
  const { state, signOut, getBasicUserInfo, getDecodedIDToken } =
    useAuthContext();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('redirectAfterLogin');
    signOut();
  };

  const checkAndUpdateSubscription = async (email) => {
    try {
      // Fetch subscription details by email
      const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN_URL}/subscription/${email}`
      );
      const subscription = response.data;

      // Check if the subscription has expired
      const currentDate = new Date();
      // console.log('Current Date:', currentDate);

      if (subscription.status !== 'not_found') {
        const subscriptionEndDate = new Date(subscription.enddate);
        // console.log('subscriptionEndDate:', subscriptionEndDate);

        if (subscriptionEndDate < currentDate && subscription.ispaid) {
          // Step 3: If expired and isPaid is true, update isPaid to false
          const updateResponse = await axios.put(
            `${process.env.REACT_APP_API_DOMAIN_URL}/subscription/${email}`,
            {
              isPaid: false,
              transactionId: null,
              userType: 'Basic',
            }
          );
          sessionStorage.setItem('isPaid', 'false');
          setUserPaid(false);
          // console.log('Subscription updated:', updateResponse.data);
        } else {
          if (subscriptionEndDate > currentDate) {
            // console.log('Subscription is still valid');
          }
          if (subscription.ispaid === false) {
            // console.log('Subscription has expired. Please renew.');
          }

          const isPaid =
            subscription.ispaid && subscriptionEndDate >= currentDate;
          sessionStorage.setItem('isPaid', isPaid.toString());
          setUserPaid(isPaid);
        }
      } else {
        // console.log(subscription.message);
        sessionStorage.setItem('isPaid', 'false');
        setUserPaid(false);
      }
    } catch (error) {
      console.error('Error checking or updating subscription:', error);
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const info = await getBasicUserInfo();
        const moreInfo = await getDecodedIDToken();

        const email = info.email || moreInfo.email || '';
        const username = info.displayName || info.givenName || '';
        setUserInfo((prev) => ({
          ...prev,
          username: username,
          email: email,
        }));
        if (email) {
          checkAndUpdateSubscription(email);
        }
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    if (state.isAuthenticated) {
      fetchUserInfo();
    }
  }, [state.isAuthenticated, getBasicUserInfo, getDecodedIDToken]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative z-[110]">
      {/* <button
        className="h-30 capitalize w-30 rounded-full font-semibold text-white bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] hover:bg-gradient-to-br hover:from-[#a6208e] hover:via-[#ca1582] hover:to-[#dd0c7e] transition duration-500 ease-in-out"
        onClick={toggleMenu}
      >
        {userInfo.username ? userInfo.username.charAt(0) : ''}
      </button> */}
      <div
        className={`h-30 w-30 rounded-full ${
          userPaid ? 'ring-4 ring-yellow-400' : ''
        } flex items-center justify-center`}
        onClick={toggleMenu}
      >
        <span className="font-semibold text-white bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] hover:bg-gradient-to-br hover:from-[#a6208e] hover:via-[#ca1582] hover:to-[#dd0c7e] h-full w-full rounded-full flex items-center justify-center transition duration-500 ease-in-out">
          {userInfo.username ? userInfo.username.charAt(0) : ''}
        </span>
        {userPaid && (
          <span className="absolute top-[-10px] right-[-10px]">👑</span>
        )}
      </div>

      {isOpen && (
        <div
          ref={menuRef}
          className="absolute z-[110] right-0 mt-2 bg-pl_bg_light_3 dark:bg-pl_bg_dark_3 rounded-10 shadow-md border border-pl_border_light_1 dark:border-pl_border_dark_1 overflow-hidden dark:text-pl_txt_dark_3 text-pl_txt_light_3"
        >
          <div className="px-10 whitespace-nowrap text-center py-10">
            <div className="w-full text-16 font-semibold capitalize">
              {userInfo.username}
            </div>
            <div className="text-14">{userInfo.email}</div>
          </div>
          <hr className="border-t border-pl_border_light_1 dark:border-pl_border_dark_1" />

          {state.isAuthenticated && (
            <>
              <button
                className="flex items-center text-14 justify-center w-full px-3 py-2 text-left hover:text-pl_prim_color_1 hover:bg-pl_bg_light_1 hover:dark:bg-pl_bg_dark_4"
                onClick={() => navigate('/my-account')}
              >
                <div>
                  <svg
                    className="fill-current"
                    width="20"
                    height="20"
                    fill="black"
                    viewBox="0 0 48 48"
                    xmlns="http://www.w3.org/2000/svg"
                    fillOpacity="0.8"
                  >
                    <path d="M 24 4 C 18.494917 4 14 8.494921 14 14 C 14 19.505079 18.494917 24 24 24 C 29.505083 24 34 19.505079 34 14 C 34 8.494921 29.505083 4 24 4 z M 24 7 C 27.883764 7 31 10.116238 31 14 C 31 17.883762 27.883764 21 24 21 C 20.116236 21 17 17.883762 17 14 C 17 10.116238 20.116236 7 24 7 z M 12.5 28 C 10.032499 28 8 30.032499 8 32.5 L 8 33.699219 C 8 36.640082 9.8647133 39.277974 12.708984 41.091797 C 15.553256 42.90562 19.444841 44 24 44 C 28.555159 44 32.446744 42.90562 35.291016 41.091797 C 38.135287 39.277974 40 36.640082 40 33.699219 L 40 32.5 C 40 30.032499 37.967501 28 35.5 28 L 12.5 28 z M 12.5 31 L 35.5 31 C 36.346499 31 37 31.653501 37 32.5 L 37 33.699219 C 37 35.364355 35.927463 37.127823 33.677734 38.5625 C 31.428006 39.997177 28.068841 41 24 41 C 19.931159 41 16.571994 39.997177 14.322266 38.5625 C 12.072537 37.127823 11 35.364355 11 33.699219 L 11 32.5 C 11 31.653501 11.653501 31 12.5 31 z"></path>
                  </svg>
                </div>
                <div className="ml-2">Account</div>
              </button>
              {/* <hr className="border-t border-pl_border_light_1 dark:border-pl_border_dark_1" /> */}
            </>
          )}

          <button
            className="flex items-center text-14 justify-center w-full px-3 py-2 text-left hover:text-pl_prim_color_1 hover:bg-pl_bg_light_1 hover:dark:bg-pl_bg_dark_4"
            onClick={() => handleLogout()}
          >
            <svg
              className="fill-current me-2"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
              fillOpacity="0.8"
            >
              <path d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12M4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z" />
            </svg>
            Logout
          </button>
        </div>
      )}
    </div>
  );
}

export default UserProfile;
