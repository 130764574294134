import React, { useState, useEffect, useRef } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { typesenseServerConfig } from '../../services/typesense-server-config';
import { SearchClient as TypesenseSearchClient } from 'typesense';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
} from 'chart.js';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';

const Stats = () => {
  const [indexSize, setIndexSize] = useState(0);
  const [supremeCourt, setSupremeCourt] = useState(0);
  const [courtAppeal, setCourtAppeal] = useState(0);
  const [slrCount, setSlrCount] = useState(0);
  const [nlrCount, setNlrCount] = useState(0);
  const [topJudges, setTopJudges] = useState([]);
  const [yearWiseData, setYearWiseData] = useState([]);
  const [minMaxDates, setMinMaxDates] = useState({});
  const chartRef = useRef(null);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "page_view",
      event_label: "Stats Page",
      page: "Stats Page",
      path: window.location.pathname,
    });
  }, []);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    BarController
  );

  const INDEX_NAME = process.env.REACT_APP_TYPESENSE_COLLECTION_NAME;
  let token = 'noToken';
  const TYPESENSE_SERVER_CONFIG = typesenseServerConfig(token);

  const fetchData = async () => {
    try {
      const typesenseSearchClient = new TypesenseSearchClient(
        TYPESENSE_SERVER_CONFIG
      );

      // Fetch index size
      const indexSizeRes = await typesenseSearchClient
        .collections(INDEX_NAME)
        .documents()
        .search({ q: '*', sort_by: 'date:desc' });
      setIndexSize(indexSizeRes.found);

      // Fetch Supreme Court size
      const scSizeRes = await typesenseSearchClient
        .collections(INDEX_NAME)
        .documents()
        .search({ q: '*', filter_by: 'sc_or_ca:SC', sort_by: 'date:desc' });
      setSupremeCourt(scSizeRes.found);

      // Fetch Court of Appeal size
      const caSizeRes = await typesenseSearchClient
        .collections(INDEX_NAME)
        .documents()
        .search({ q: '*', filter_by: 'sc_or_ca:CA', sort_by: 'date:desc' });
      setCourtAppeal(caSizeRes.found);

      // Fetch SLR size
      const slrSizeRes = await typesenseSearchClient
        .collections(INDEX_NAME)
        .documents()
        .search({ q: '*', filter_by: 'report:Sri-LR', sort_by: 'date:desc' });
      setSlrCount(slrSizeRes.found);

      // Fetch NLR size
      const nlrSizeRes = await typesenseSearchClient
        .collections(INDEX_NAME)
        .documents()
        .search({ q: '*', filter_by: 'report:NLR', sort_by: 'date:desc' });
      setNlrCount(nlrSizeRes.found);

      // Fetch Top Judges
      const judgesRes = await typesenseSearchClient
        .collections(INDEX_NAME)
        .documents()
        .search({
          q: '*',
          facet_by: 'judge_final',
          max_facet_values: 10,
        });
      const topJudgesData = judgesRes.facet_counts[0].counts.map((judge) => ({
        name: judge.value,
        count: judge.count,
      }));
      setTopJudges(topJudgesData);

      // Fetch Min-Max Dates for all case types
      const minMaxRes = await getMinMaxDates(typesenseSearchClient);
      setMinMaxDates(minMaxRes);

      // Fetch Year-Wise Decisions
      const yearWiseRes = await getYearWiseDecisions(typesenseSearchClient);
      setYearWiseData(yearWiseRes);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getMinMaxDates = async (typesenseSearchClient) => {
    const minMaxDates = {};
    const caseTypes = ['SC', 'CA', 'Sri-LR', 'NLR'];

    // Fetch min and max dates for each case type
    for (let caseType of caseTypes) {
      let { minDate, maxDate } = await getMinMaxDatesForCaseType(
        typesenseSearchClient,
        caseType
      );
      minMaxDates[caseType] = {
        minDate: minDate ? format(new Date(minDate), 'dd/MM/yyyy') : 'N/A',
        maxDate: maxDate ? format(new Date(maxDate), 'dd/MM/yyyy') : 'N/A',
      };
    }

    let { minDate: totalMinDate, maxDate: totalMaxDate } =
      await getMinMaxDatesForTotal(typesenseSearchClient);
    minMaxDates['total'] = {
      minDate: totalMinDate
        ? format(new Date(totalMinDate), 'dd/MM/yyyy')
        : 'N/A',
      maxDate: totalMaxDate
        ? format(new Date(totalMaxDate), 'dd/MM/yyyy')
        : 'N/A',
    };

    return minMaxDates;
  };

  const getMinMaxDatesForCaseType = async (typesenseSearchClient, caseType) => {
    try {
      let minDate = null;
      let maxDate = null;

      if (caseType === 'SC' || caseType === 'CA') {
        // Fetching min date for SC/CA
        let minResults = await typesenseSearchClient
          .collections(INDEX_NAME)
          .documents()
          .search({
            q: '*',
            filter_by: `sc_or_ca:${caseType}`,
            sort_by: 'date:asc',
            per_page: 1,
          });
        minDate =
          minResults.hits.length > 0 ? minResults.hits[0].document.date : null;

        // Fetching max date for SC/CA
        let maxResults = await typesenseSearchClient
          .collections(INDEX_NAME)
          .documents()
          .search({
            q: '*',
            filter_by: `sc_or_ca:${caseType}`,
            sort_by: 'date:desc',
            per_page: 1,
          });
        maxDate =
          maxResults.hits.length > 0 ? maxResults.hits[0].document.date : null;
      } else if (caseType === 'Sri-LR' || caseType === 'NLR') {
        // Fetching min date for Sri-LR/NLR
        let minResults = await typesenseSearchClient
          .collections(INDEX_NAME)
          .documents()
          .search({
            q: '*',
            filter_by: `report:${caseType}`,
            sort_by: 'date:asc',
            per_page: 1,
          });
        minDate =
          minResults.hits.length > 0 ? minResults.hits[0].document.date : null;

        // Fetching max date for Sri-LR/NLR
        let maxResults = await typesenseSearchClient
          .collections(INDEX_NAME)
          .documents()
          .search({
            q: '*',
            filter_by: `report:${caseType}`,
            sort_by: 'date:desc',
            per_page: 1,
          });
        maxDate =
          maxResults.hits.length > 0 ? maxResults.hits[0].document.date : null;
      }

      return { minDate, maxDate };
    } catch (error) {
      console.error(`Error fetching min/max dates for ${caseType}:`, error);
      return { minDate: null, maxDate: null };
    }
  };

  const getMinMaxDatesForTotal = async (typesenseSearchClient) => {
    try {
      let minDate = null;
      let maxDate = null;

      // Fetching min date for all decisions (no filter)
      let minResults = await typesenseSearchClient
        .collections(INDEX_NAME)
        .documents()
        .search({
          q: '*',
          sort_by: 'date:asc',
          per_page: 1,
        });
      minDate =
        minResults.hits.length > 0 ? minResults.hits[0].document.date : null;

      // Fetching max date for all decisions (no filter)
      let maxResults = await typesenseSearchClient
        .collections(INDEX_NAME)
        .documents()
        .search({
          q: '*',
          sort_by: 'date:desc',
          per_page: 1,
        });
      maxDate =
        maxResults.hits.length > 0 ? maxResults.hits[0].document.date : null;

      return { minDate, maxDate };
    } catch (error) {
      console.error('Error fetching min/max dates for total:', error);
      return { minDate: null, maxDate: null };
    }
  };

  const getYearWiseDecisions = async (typesenseSearchClient) => {
    let results = await typesenseSearchClient
      .collections(INDEX_NAME)
      .documents()
      .search({
        q: '*',
        facet_by: 'decision_year',
        per_page: 0,
        max_facet_values: 100,
      });
    return results.facet_counts[0].counts;
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (yearWiseData.length > 0) {
      const sortedYearWiseData = [...yearWiseData].sort(
        (a, b) => a.value - b.value
      );

      const years = sortedYearWiseData.map((data) => data.value);
      const decisions = sortedYearWiseData.map((data) => data.count);

      createYearWiseChart(years, decisions);
    }
  }, [yearWiseData]);

  const createYearWiseChart = (years, decisions) => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = document.getElementById('yearWiseChart').getContext('2d');
    const newChart = new ChartJS(ctx, {
      type: 'bar',
      data: {
        labels: years,
        datasets: [
          {
            label: 'Decision count',
            data: decisions,
            backgroundColor: '#dd0c7e',
            borderColor: '#dd0c7e',
            borderWidth: 0,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: { ticks: { font: { size: 9 }, autoSkip: true } },
          y: {
            beginAtZero: true,
            ticks: { font: { size: 9 }, autoSkip: true },
          },
        },
      },
    });
    chartRef.current = newChart;
  };

  return (
    <MainLayout>
        <Helmet>
          <title>Stats - Paralegal.lk</title>
          <meta
            name="description"
            content="Learn about Paralegal.lk, a platform providing access to Sri Lankan legal resources, including over 20,000 appellate court decisions since 1880."
          />
          <link rel="canonical" href="https://www.paralegal.lk/stats" />
        </Helmet>

      <div className="flex flex-col justify-center mx-auto w-full max-w-6xl mt-50 p-20 text-pl_txt_light_4 dark:text-pl_txt_dark_2">
        <h1 className="mb-4 font-semibold text-20 md:text-22">
          A statistical overview of{' '}
          <a
            href="https://www.paralegal.lk"
            className="font-bold bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text"
          >
            paralegal.lk
          </a>
          's data collection
        </h1>

        <p className="leading-relaxed mb-2">
          We provide below a brief overview of{' '}
          <a
            href="https://www.paralegal.lk"
            className="font-semibold bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text"
          >
            Paralegal
          </a>
          's data collection. We report key metrics such as the total number of
          decisions indexed by court, the time range of decisions, and the top
          10 judges based on the number of decisions attributed to them. Note
          that SC denotes the Supreme Court, CA denotes the Court of Appeal, Sri
          LR stands for the Sri Lanka Law Reports, and NLR denotes the older New
          Law Reports.
        </p>

        <div className="w-full bg-pl_bg_light_1 dark:bg-pl_bg_dark_3  border dark:border-pl_border_dark_1 border-pl_border_light_1 rounded-xl text-14 text-left">
          <table className="table-auto w-full">
            <thead>
              <tr className="border-b dark:border-pl_border_dark_1 border-pl_border_light_1">
                <th className="p-10">#</th>
                <th className="p-10">Time range</th>
                <th className="p-10">Decision count</th>
              </tr>
            </thead>
            <tbody>
              <tr className="hover:bg-pl_bg_light_2 hover:dark:bg-pl_bg_dark_2">
                <td className="py-5 px-10"># Total decisions</td>
                <td className="py-5 px-10">
                  {minMaxDates?.total?.minDate} - {minMaxDates?.total?.maxDate}
                </td>
                <td className="py-5 px-10">{indexSize}</td>
              </tr>
              <tr className="hover:bg-pl_bg_light_2 hover:dark:bg-pl_bg_dark_2">
                <td className="py-5 px-10"># SC decisions</td>
                <td className="py-5 px-10">
                  {minMaxDates?.SC?.minDate} - {minMaxDates?.SC?.maxDate}
                </td>
                <td className="py-5 px-10">{supremeCourt}</td>
              </tr>
              <tr className="hover:bg-pl_bg_light_2 hover:dark:bg-pl_bg_dark_2">
                <td className="py-5 px-10"># CA decisions</td>
                <td className="py-5 px-10">
                  {minMaxDates?.CA?.minDate} - {minMaxDates?.CA?.maxDate}
                </td>
                <td className="py-5 px-10">{courtAppeal}</td>
              </tr>
              <tr className="hover:bg-pl_bg_light_2 hover:dark:bg-pl_bg_dark_2">
                <td className="py-5 px-10"># Sri LR decisions</td>
                <td className="py-5 px-10">
                  {minMaxDates?.['Sri-LR']?.minDate} -{' '}
                  {minMaxDates?.['Sri-LR']?.maxDate}
                </td>
                <td className="py-5 px-10">{slrCount}</td>
              </tr>
              <tr className="hover:bg-pl_bg_light_2 hover:dark:bg-pl_bg_dark_2">
                <td className="py-5 px-10"># NLR decisions</td>
                <td className="py-5 px-10">
                  {minMaxDates?.NLR?.minDate} - {minMaxDates?.NLR?.maxDate}
                </td>
                <td className="py-5 px-10">{nlrCount}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">
          Decision count by judge
        </h2>
        <p className="leading-relaxed mb-2">
          <a
            href="https://www.paralegal.lk"
            className="font-semibold bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text"
          >
            Paralegal
          </a>{' '}
          enables filtering case law search results by judge name. The below
          table provides an overview of the number of decisions indexed by the
          search engine for each of these judges.
        </p>

        <div className="w-full bg-pl_bg_light_1 dark:bg-pl_bg_dark_3 border dark:border-pl_border_dark_1 border-pl_border_light_1 rounded-xl text-14 text-left">
          <table className="table-auto w-full">
            <thead>
              <tr className="border-b dark:border-pl_border_dark_1 border-pl_border_light_1">
                <th className="p-10">Judge</th>
                <th className="p-10">Decision count</th>
              </tr>
            </thead>
            <tbody>
              {topJudges.map((judge, index) => (
                <tr
                  key={index}
                  className="hover:bg-pl_bg_light_2 hover:dark:bg-pl_bg_dark_2"
                >
                  <td className="py-5 px-10">{judge.name}</td>
                  <td className="py-5 px-10">{judge.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">
          Decision count by year
        </h2>

        <p className="leading-relaxed mb-2">
          The Supreme Court and the Court of Appeal began publishing all their
          decisions online starting from 2012. That explains the big jump in the
          decision count around that time. For the years before 2012,{' '}
          <a
            href="https://www.paralegal.lk"
            className="font-semibold bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text"
          >
            paralegal.lk
          </a>{' '}
          indexes only the reported decisions in the Sri Lanka Law Reports and
          the New Law Reports. Our coverage of reported decisions is nearly
          complete and we are working on adding the remaining volumes to the
          database. For details, check the section on{' '}
          <a href="#sri-lr-volumes">Sri LR volumes yet to be added</a>.
        </p>

        <div>
          <canvas id="yearWiseChart" style={{ height: '50vh' }}></canvas>
        </div>

        <div id="sri-lr-volumes">
          <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">
            Sri LR volumes yet to be added
          </h2>
          <p className="leading-relaxed mb-2">
            At present, the following Sri LR volumes are yet to be added to the
            database. They should be online by the end of October 31, 2024.
          </p>
          <ol>
            <li className="leading-relaxed mb-2 px-2 text-14">2015 - 2021</li>
          </ol>
        </div>
      </div>
    </MainLayout>
  );
};

export default Stats;
