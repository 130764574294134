import React from 'react';
import PaymentButton from './payment/paymentButton';

const CenturySubscription = () => {
  return (
    <div className="bg-pl_bg_light_1 dark:bg-pl_bg_dark_1 text-pl_txt_light_1 dark:text-pl_txt_dark_1 flex flex-col items-center justify-center px-6 md:px-10 my-20">
      <h1 className="text-28 font-medium mb-20">
        Become a{' '}
            <span className="font-bold bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text">
              Century Catalyst
            </span>{' '}today!
      </h1>
      <div className="flex flex-wrap justify-center gap-40 max-w-7xl w-full">
        {/* Annual Subscription */}
        <div className="text-14 bg-pl_bg_light_3 dark:bg-pl_bg_dark_3 rounded-10 shadow-md border border-pl_border_light_1 dark:border-pl_border_dark_1 overflow-hidden dark:text-pl_txt_dark_1 text-pl_txt_light_4 flex flex-col max-w-80 min-w-60 p-30">
          <h2 className="text-xl font-semibold text-pl_prim_color_1 mb-5">
            Century Catalyst Plan
          </h2>
          <p className="text-16 mb-5">Our exclusive offer for the first 100 subscribers!</p>
          <p className="text-36 font-semibold mb-5">
            Rs. 24,000<span className="text-16 font-normal">/year</span>
          </p>
          <PaymentButton DurationTime="1 Year" userType="Century Circle" />
          <ul className="text-14 space-y-3 flex-grow text-left my-20 list-none">
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">
                Full access to the case law database and powerful search tools
              </span>
            </li>
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">
                3 months complimentary access: get 15 months for the price of 12
              </span>
            </li>
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">Priority support with response times less than 48 hrs </span>
            </li>
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">Regular feature updates and enhancements</span>
            </li>
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">
                Save 20% compared to monthly billing
              </span>
            </li>
          </ul>
        </div>

        {/* Monthly Subscription */}
        {/* <div className="text-14 bg-pl_bg_light_2 dark:bg-pl_bg_dark_2 rounded-10 shadow-md border border-pl_border_light_1 dark:border-pl_border_dark_1 overflow-hidden dark:text-pl_txt_dark_1 text-pl_txt_light_4 flex flex-col max-w-80 min-w-60 p-30">
          <h2 className="text-xl font-semibold text-pl_prim_color_1 mb-5">
            Monthly Subscription
          </h2>
          <p className="text-16 mb-5">Flexible monthly payments.</p>
          <p className="text-36 font-semibold mb-5">
            Rs. 2,500<span className="text-16 font-normal">/month</span>
          </p>
          <PaymentButton DurationTime="1 Month" userType="Century Circle" />
          <ul className="text-14 space-y-3 flex-grow text-left my-20 list-none">
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">Full access to the case law database and powerful search tools</span>
            </li>
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">
              7 days complimentary access: get an extra week for free
              </span>
            </li>
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">
              Standard support (3-5 business day response time)
              </span>
            </li>
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">Regular feature updates and enhancements</span>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default CenturySubscription;
