import { useEffect } from 'react';
import MainLayout from "../../layouts/MainLayout";
import { Helmet } from 'react-helmet';

const Refund = () => {
  useEffect(() => {
    const storedDarkMode = localStorage.getItem('darkMode') === 'true';
    document.documentElement.classList.toggle('dark', storedDarkMode);
  }, []);
  return (
    <MainLayout>
        <Helmet>
          <title>Refund - Paralegal.lk</title>
          <meta
            name="description"
            content="Learn about Paralegal.lk, a platform providing access to Sri Lankan legal resources, including over 20,000 appellate court decisions since 1880."
          />
          <link rel="canonical" href="https://www.paralegal.lk/refund" />
        </Helmet>

        <div className="flex flex-col justify-center mx-auto w-full max-w-6xl mt-50 p-20 text-pl_txt_light_4 dark:text-pl_txt_dark_2">
            <h1 className="mb-4 font-semibold text-20 md:text-22">Refund Policy</h1>
            <p className="leading-relaxed mb-2">Effective Date: December 1, 2024</p>
            <p className="leading-relaxed mb-2">
                We strive to ensure our customers are satisfied with our services. This Refund Policy outlines the terms and conditions under which refunds are granted for our annual subscription plans.
            </p>
            <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">1. Eligibility for Refunds</h2>
            <ul className="list-disc list-inside leading-relaxed mb-2 [text-indent:-1.5rem] [padding-left:3.5rem]">
                <li>
                    If you cancel within the first 30 days of your annual subscription, you are eligible for a full refund.
                </li>
                <li>
                    If you are a monthly subscriber, you may cancel at any time, but you will not be eligible for a refund. You will have premium access until the end of the billing cycle.
                </li>
                <li>
                <strong>Service Issues:</strong> If there are substantial service disruptions or performance issues directly caused by Paralegal.lk that remain unresolved after reasonable efforts, you may be eligible for a prorated refund.
                </li>
            </ul>
            <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">2. Non-Refundable Cases</h2>
            <ul className="list-disc list-inside leading-relaxed mb-2 [text-indent:-1.5rem] [padding-left:3.5rem]">
                <li>Cancellations made after 30 days from the subscription start date.</li>
                <li>Partial months or unused portions of the annual subscription.</li>
                <li>Downgrades or unused features of the product.</li>
                <li>Violations of our Terms of Service.</li>
            </ul>
            <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">3. Refund Process</h2>
            <p className="leading-relaxed mb-2">To request a refund, follow these steps:</p>
            <ol className="list-decimal list-inside pl-6 leading-relaxed mb-2 [text-indent:-1.5rem] [padding-left:3.5rem]">
                <li>Contact our support team at <a href="mailto:admin@paralegal.lk" className="hover:underline text-pl_prim_color_1">admin@paralegal.lk</a> with your subscription details and reason for the refund request.</li>
                <li>Our team will review your request and respond within 7 business days.</li>
                <li>Approved refunds will be processed within 14 business days via the original payment method.</li>
            </ol>
            <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">4. Changes to the Refund Policy</h2>
            <p className="leading-relaxed mb-2">
                We reserve the right to modify this Refund Policy at any time. Changes will be posted on our website, and continued use of our services after such changes indicates acceptance of the updated policy.
            </p>
            <p className="leading-relaxed mb-2">
                For any questions regarding this Refund Policy, please contact us at <a href="mailto:admin@paralegal.lk" className="hover:underline text-pl_prim_color_1">admin@paralegal.lk</a>.
            </p>
            <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Paralegal (Pvt) Ltd</h2>
            <p className="leading-relaxed mb-2">
                <strong>
                    95, Chemmani Road,<br />
                    Nallur,<br />
                    Jaffna 40000
                </strong>
            </p>
            <p className="leading-relaxed mb-2">
                <strong>email:</strong> <a href="mailto:admin@paralegal.lk" className="hover:underline text-pl_prim_color_1">admin@paralegal.lk</a>
            </p>
        </div>
    </MainLayout>

  );
};

export default Refund;
