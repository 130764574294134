import React, { useEffect } from 'react';
import LogoHeader from '../components/Header/LogoHeader';
import Footer from '../components/Footer/Footer';

const MainLayout = ({ children }) => {
  useEffect(() => {
    sessionStorage.removeItem('isRedirected');
  }, []);
  return (
    <div className="container-fluid flex flex-col bg-pl_bg_light_3 dark:bg-pl_bg_dark_1 justify-between min-h-screen">
      <LogoHeader />

      <main className="container mx-auto flex-grow flex items-center justify-center mb-20 mt-10 md:mt-40 text-14 md:text-15">
        {children}
      </main>

      <Footer />
    </div>
  );
};

export default MainLayout;
