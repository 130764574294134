import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

function MenuCard() {
  const [isOpen, setIsOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const handleStatsClick = () => {
    window.location.href = '/stats';
  };

  const handlePerformanceClick = () => {
    window.location.href = '/benchmark';
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    document.documentElement.classList.toggle('dark', newDarkMode);
    localStorage.setItem('darkMode', newDarkMode);
  };

  useEffect(() => {
    const storedDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(storedDarkMode);
    document.documentElement.classList.toggle('dark', storedDarkMode);

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative z-[110] inline-block dark:text-pl_txt_dark_2 text-pl_txt_light_3 whitespace-nowrap">
      <button
        className="h-30 w-30 flex justify-center items-center"
        onClick={toggleMenu}
      >
        <svg
          className="h-30 w-30 fill-current"
          viewBox="0 0 30 30"
          fill="black"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M3.75 7.5H26.25V10H3.75V7.5ZM3.75 13.75H26.25V16.25H3.75V13.75ZM3.75 20H26.25V22.5H3.75V20Z" />
        </svg>
      </button>

      {isOpen && (
        <div
          ref={menuRef}
          className="absolute z-[110] right-0 text-14 bg-pl_bg_light_3 dark:bg-pl_bg_dark_3 rounded-10 shadow-md border border-pl_border_light_1 dark:border-pl_border_dark_1 overflow-hidden dark:text-pl_txt_dark_2 text-pl_txt_light_4"
        >
          <button
            className="flex items-center w-full px-3 py-2 text-left hover:text-pl_prim_color_1 hover:bg-pl_bg_light_1 hover:dark:bg-pl_bg_dark_4"
            onClick={() => navigate('/help')}
          >
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="black"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-current"
                fillOpacity="0.6"
              >
                <path d="M9 16H11V14H9V16ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C8.93913 4 7.92172 4.42143 7.17157 5.17157C6.42143 5.92172 6 6.93913 6 8H8C8 7.46957 8.21071 6.96086 8.58579 6.58579C8.96086 6.21071 9.46957 6 10 6C10.5304 6 11.0391 6.21071 11.4142 6.58579C11.7893 6.96086 12 7.46957 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 6.93913 13.5786 5.92172 12.8284 5.17157C12.0783 4.42143 11.0609 4 10 4Z" />
              </svg>
            </div>
            <div className="ml-2">Help</div>
          </button>
          <hr className="border-t border-pl_border_light_1 dark:border-pl_border_dark_1" />
          <button
            className="flex items-center w-full px-3 py-2 text-left hover:text-pl_prim_color_1 hover:bg-pl_bg_light_1 hover:dark:bg-pl_bg_dark_4"
            onClick={handlePerformanceClick}
          >
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="black"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-current"
                fillOpacity="0.6"
              >
                <path d="M11 9.47V11H14.76L13 14.53V13H9.24L11 9.47ZM13 1L6 15H11V23L18 9H13V1Z" />
              </svg>
            </div>
            <div className="ml-2">Performance</div>
          </button>

          <hr className="border-t border-pl_border_light_1 dark:border-pl_border_dark_1" />
          <button
            className="flex items-center w-full px-3 py-2 text-left hover:text-pl_prim_color_1 hover:bg-pl_bg_light_1 hover:dark:bg-pl_bg_dark_4"
            onClick={handleStatsClick}
          >
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="black"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-current"
                fillOpacity="0.6"
              >
                <path d="M21 8C19.5 8 18.7 9.4 19.1 10.5L15.5 14.1C15.2 14 14.8 14 14.5 14.1L11.9 11.5C12.3 10.4 11.5 9 10 9C8.6 9 7.7 10.4 8.1 11.5L3.5 16C2.4 15.7 1 16.5 1 18C1 19.1 1.9 20 3 20C4.4 20 5.3 18.6 4.9 17.5L9.4 12.9C9.7 13 10.1 13 10.4 12.9L13 15.5C12.7 16.5 13.5 18 15 18C16.5 18 17.3 16.6 16.9 15.5L20.5 11.9C21.6 12.2 23 11.4 23 10C23 8.9 22.1 8 21 8ZM15 9L15.9 6.9L18 6L15.9 5.1L15 3L14.1 5.1L12 6L14.1 6.9L15 9ZM3.5 11L4 9L6 8.5L4 8L3.5 6L3 8L1 8.5L3 9L3.5 11Z" />
              </svg>
            </div>
            <div className="ml-2">Stats</div>
          </button>
          <hr className="border-t border-pl_border_light_1 dark:border-pl_border_dark_1" />
          <button
            className="flex items-center w-full px-3 py-2 text-left hover:text-pl_prim_color_1 hover:bg-pl_bg_light_1 hover:dark:bg-pl_bg_dark_4"
            onClick={() => navigate('/about')}
          >
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="black"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-current"
                fillOpacity="0.6"
              >
                <path d="M11 9H13V7H11M12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM11 17H13V11H11V17Z" />
              </svg>
            </div>
            <div className="ml-2">About</div>
          </button>
          <hr className="border-t border-pl_border_light_1 dark:border-pl_border_dark_1" />
          <button
            className="flex items-center w-full px-3 py-2 text-left hover:text-pl_prim_color_1 hover:bg-pl_bg_light_1 hover:dark:bg-pl_bg_dark_4"
            onClick={toggleDarkMode}
          >
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="black"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-current"
                fillOpacity="0.6"
              >
                <path d="M14.7917 3.40833L12.6834 5.025L13.4417 7.575L11.2501 6.06667L9.05839 7.575L9.81672 5.025L7.70839 3.40833L10.3667 3.33333L11.2501 0.833334L12.1334 3.33333L14.7917 3.40833ZM17.7084 9.16667L16.3417 10.2083L16.8334 11.8583L15.4167 10.8833L14.0001 11.8583L14.4917 10.2083L13.1251 9.16667L14.8417 9.125L15.4167 7.5L15.9917 9.125L17.7084 9.16667ZM15.8084 13.2917C16.5001 13.225 17.2417 14.2083 16.8001 14.8333C16.5334 15.2083 16.2501 15.5583 15.9001 15.8917C12.6417 19.1667 7.36672 19.1667 4.11672 15.8917C0.85839 12.6417 0.85839 7.35833 4.11672 4.10833C4.45006 3.775 4.80006 3.475 5.17506 3.20833C5.80006 2.76667 6.78339 3.50833 6.71672 4.2C6.49172 6.58333 7.29172 9.05833 9.12506 10.8833C10.9501 12.7167 13.4167 13.5167 15.8084 13.2917ZM14.4417 14.975C12.0834 14.8417 9.75006 13.8667 7.94172 12.0833C6.13339 10.2583 5.16672 7.91667 5.03339 5.56667C2.69172 8.18333 2.78339 12.2 5.29172 14.7167C7.80839 17.225 11.8251 17.3167 14.4417 14.975Z" />
              </svg>
            </div>
            <div className="flex items-center gap-10 whitespace-nowrap w-full">
              <div className="ml-2">Dark mode</div>
              <label className="relative inline-flex cursor-pointer items-center">
                <input
                  type="checkbox"
                  checked={darkMode}
                  onChange={toggleDarkMode}
                  className="peer sr-only"
                />
                <div className="peer h-3 w-6 rounded-full border border-pl_border_light_1 dark:border-0  after:absolute after:top-[2px] after:left-[2px] after:h-[8px] after:w-2.5 after:rounded-md after:border-0 after:bg-pl_border_light_1 after:transition-all after:content-[''] peer-checked:bg-pl_prim_color_1 peer-checked:after:translate-x-full peer-focus:ring-pl_prim_color_1"></div>
              </label>
            </div>
          </button>
        </div>
      )}
    </div>
  );
}

export default MenuCard;
