/*global payhere*/
import React, { useEffect, useState } from 'react';
import { useAuthContext } from '@asgardeo/auth-react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TermsPopup from './TermsPopup';

const DurationStr = {
  '1 Year': 'Annual',
  '1 Month': 'Monthly',
  '1 Day': 'Day Pass',
};

const PaymentButton = ({ DurationTime, userType }) => {
  const { state, signIn, getBasicUserInfo, getDecodedIDToken } =
    useAuthContext();
  const [showTerms, setShowTerms] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const handlePayment = async () => {
    try {
      // Step 1: Check Authentication
      if (!state.isAuthenticated) {
        const currentPath = window.location.pathname;
        sessionStorage.setItem('redirectAfterLogin', currentPath);
        await signIn();
        return;
      }

      // Step 2: Fetch User Info
      const userInfo = await getBasicUserInfo();
      const moreInfo = await getDecodedIDToken();
      const email = userInfo.email || moreInfo.email;

      if (!email) {
        throw new Error('User email is missing.');
      }

      // Step 3: Check if the user is already paid
      const plan = DurationStr[DurationTime];

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_DOMAIN_URL}/subscription/${email}`
        );
        const data = response.data;

        if (data.status === 'not_found') {
          // console.log('Subscription not found for the user.');
        } else if (data.ispaid) {
          // console.log('Subscription already paid.');
          toast.warning('You have already paid.');
          return;
        } else {
          // console.log('Subscription expired.');
        }
      } catch (error) {
        console.error(error);
      }

      // Step 5: Generate Payment Hash
      const paymentDetails = {
        order_id: email,
        amount:
          plan === 'Annual'
            ? '24000.00'
            : plan === 'Monthly'
            ? '2500.00'
            : '500.00',
        currency: 'LKR',
        first_name: userInfo.first_name || '',
        last_name: userInfo.last_name || '',
        email: email,
        phone: '',
        address: '',
        city: '',
        country: 'Sri Lanka',
        custom_1: userType,
        custom_2: plan,
      };

      const hashResponse = await fetch(
        `${process.env.REACT_APP_API_DOMAIN_URL}/payment/start`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(paymentDetails),
        }
      );

      if (!hashResponse.ok) {
        throw new Error('Failed to generate hash for payment.');
      }

      const { hash, merchant_id } = await hashResponse.json();

      // Step 6: Initialize Payment
      const payment = {
        sandbox: process.env.REACT_APP_SANDBOX,
        merchant_id: merchant_id,
        return_url: process.env.REACT_APP_RETURN_URL,
        cancel_url: process.env.REACT_APP_CANCEL_URL,
        notify_url: process.env.REACT_APP_NOTIFY_URL,
        order_id: paymentDetails.order_id,
        items: `${plan} Subscription`,
        amount: paymentDetails.amount,
        currency: paymentDetails.currency,
        first_name: paymentDetails.first_name,
        last_name: paymentDetails.last_name,
        email: paymentDetails.email,
        phone: paymentDetails.phone,
        address: paymentDetails.address,
        city: paymentDetails.city,
        country: paymentDetails.country,
        hash: hash,
        custom_1: paymentDetails.custom_1,
        custom_2: paymentDetails.custom_2,
      };

      if (!acceptedTerms) {
        setShowTerms(true);
        return;
      }

      payhere.startPayment(payment);
    } catch (error) {
      console.error('Payment process error:', error.message);
    }
  };

  useEffect(() => {
    if (acceptedTerms) {
      handlePayment();
    }
  }, [acceptedTerms]);

  return (
    <>
      <ToastContainer position="bottom-right" autoClose={5000} />
      <button
        onClick={handlePayment}
        className="text-white bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] hover:bg-gradient-to-br hover:from-[#a6208e] hover:via-[#ca1582] hover:to-[#dd0c7e] transition duration-500 ease-in-out rounded-25 text-18 px-15 h-50 "
      >
        Choose {DurationStr[DurationTime]} Plan
      </button>

      {showTerms && (
        <TermsPopup
          onAccept={() => {
            setAcceptedTerms(true);
            setShowTerms(false);
          }}
          onClose={() => setShowTerms(false)}
        />
      )}
    </>
  );
};

export default PaymentButton;
