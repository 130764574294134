import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuthContext } from '@asgardeo/auth-react';
import MainLayout from '../../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import ChangePassword from './ChangePassword';
import { Helmet } from 'react-helmet';

const Account = () => {
  const { state, getBasicUserInfo, getDecodedIDToken } = useAuthContext();
  const [userInfo, setUserInfo] = useState({
    username: '',
    email: '',
    startdate: '',
    enddate: '',
    plan: '',
    usertype: '',
    subscriptionFound: false,
  });
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const formatDateTime = (date) => {
    return new Date(date).toLocaleString();
  };

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        if (!state.isAuthenticated) return;
        setLoading(true);

        const info = await getBasicUserInfo();
        const moreInfo = await getDecodedIDToken();

        const email = info.email || moreInfo.email || '';
        const username = info.displayName || info.givenName || '';
        const givenname = info.givenName || info.displayName;

        const response = await axios.get(
          `${process.env.REACT_APP_API_DOMAIN_URL}/subscription/${email}`
        );
        const subscription = response.data;

        if (subscription.status !== 'not_found') {
          setUserInfo({
            username: username,
            givenname: givenname,
            email: email,
            startdate: subscription.startdate,
            enddate: subscription.enddate,
            plan: subscription.plan,
            usertype: subscription.usertype,
            ispaid: subscription.ispaid,
            subscriptionFound: true,
          });
        } else {
          setUserInfo({
            username: username,
            givenname: givenname,
            email: email,
            subscriptionFound: false,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountDetails();
  }, [state.isAuthenticated, getBasicUserInfo, getDecodedIDToken]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <MainLayout>
      {/* Set SEO Metadata and Canonical Tag */}
        <Helmet>
          <title>My Account | :paralegal.lk</title>
          <meta name="robots" content="noindex" />
        </Helmet>

      <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 p-6 md:p-8 lg:p-12 mt-30 lg:mt-10 mb-[-25px]">
        <div className="max-w-7xl mx-auto">
          {/* Header Section */}
          <div className="mb-8 bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6 md:p-8">
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-white">
              Welcome,{' '}
              {loading ? (
                <span className="inline-block w-32 h-8 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
              ) : (
                <span className="text-pl_prim_color_1">
                  {userInfo.givenname || ''}!
                </span>
              )}
            </h1>
            <p className="mt-2 text-lg text-gray-600 dark:text-gray-300">
              Manage your account settings and subscription details
            </p>
          </div>

          {/* Main Content Grid */}
          <div className="grid gap-8 md:grid-cols-2">
            {/* Personal Details Card */}
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm overflow-hidden">
              <div className="p-6 md:p-8">
                <h2 className="flex items-center text-xl font-semibold text-gray-900 dark:text-white mb-6">
                  <svg
                    className="w-[24px] h-[24px] mr-2 text-indigo-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                  Personal Details
                </h2>

                <div className="space-y-4">
                  <div className="flex items-center py-2">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400 w-24">
                      Name
                    </span>
                    {loading ? (
                      <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse w-32" />
                    ) : (
                      <span className="text-gray-900 dark:text-white">
                        {userInfo.username}
                      </span>
                    )}
                  </div>

                  <div className="flex items-center py-2">
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400 w-24">
                      Email
                    </span>
                    {loading ? (
                      <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse w-40" />
                    ) : (
                      <span className="text-gray-900 dark:text-white">
                        {userInfo.email}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mt-8">
                  <button
                    onClick={() =>
                      (window.location.href = `https://accounts.asgardeo.io/t/paralegallk/accountrecoveryendpoint/recoveraccountrouter.do?client_id=${process.env.REACT_APP_ASGARDEO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_SIGN_IN_URL}`)
                    }
                    className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg text-white bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] hover:bg-gradient-to-br hover:from-[#a6208e] hover:via-[#ca1582] hover:to-[#dd0c7e] transition duration-500 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#dd0c7e]"
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </div>

            {/* Subscription Details Card */}
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm overflow-hidden">
              <div className="p-6 md:p-8">
                <h2 className="flex items-center text-xl font-semibold text-gray-900 dark:text-white mb-6">
                  <svg
                    className="w-[24px] h-[24px] mr-2 text-indigo-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                    />
                  </svg>
                  Subscription Details
                </h2>

                {loading ? (
                  <div className="space-y-4">
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse w-40" />
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse w-32" />
                  </div>
                ) : userInfo.subscriptionFound && userInfo.ispaid ? (
                  <div className="space-y-4">
                    <div className="flex items-center py-2">
                      <span className="text-sm font-medium text-gray-500 dark:text-gray-400 w-24">
                        Plan
                      </span>
                      <span className="text-gray-900 dark:text-white">
                        {userInfo.plan}
                      </span>
                    </div>
                    <div className="flex items-center py-2">
                      <span className="text-sm font-medium text-gray-500 dark:text-gray-400 w-24">
                        Start Date
                      </span>
                      <span className="text-gray-900 dark:text-white">
                        {formatDateTime(userInfo.startdate)}
                      </span>
                    </div>
                    <div className="flex items-center py-2">
                      <span className="text-sm font-medium text-gray-500 dark:text-gray-400 w-24">
                        End Date
                      </span>
                      <span className="text-gray-900 dark:text-white">
                        {formatDateTime(userInfo.enddate)}
                      </span>
                    </div>
                    <div className="flex items-center py-2">
                      <span className="text-sm font-medium text-gray-500 dark:text-gray-400 w-24">
                        User Type
                      </span>
                      <span className="text-gray-900 dark:text-white">
                        {userInfo.usertype}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="text-center space-y-6">
                    <p className="text-lg text-gray-600 dark:text-gray-300">
                      {userInfo.subscriptionFound ? (
                        <>
                          Your{' '}
                          <span className="font-semibold">{userInfo.plan}</span>{' '}
                          plan has expired.
                        </>
                      ) : (
                        'No active subscription found.'
                      )}
                    </p>
                    <button
                      onClick={() => navigate('/pricing')}
                      className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg text-white bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] hover:bg-gradient-to-br hover:from-[#a6208e] hover:via-[#ca1582] hover:to-[#dd0c7e] transition duration-500 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#dd0c7e]"
                    >
                      Subscribe Now
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export default Account;
